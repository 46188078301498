import { mapGetters } from 'vuex'
import '@/css/home.scss'
import { onBeforeMount } from 'vue'
// const navModule_1 = () => import('@/pages/modules/navModule_1.vue') //代码切割

export default {
	components: {
		// navModule_1,
	},
	computed: {
		...mapGetters({
			host: 'host',
			showDebug: 'showDebug',
			deviceType: 'deviceType',
		}),
	},
	beforeMount () {
		const arr = require('@/blogs/index.js').default
		this.card = arr.map((e ,i) => ({
			title: e.title,
			desc: e.desc,
			url: e.url,
			img: require(`@/assets/i${i + 1}.png`)
		}))
	},
}
