<template>
  <p class="mypic">
    <img :class="size" :alt="alt" :src="require(`@/assets/${name}.png`)" />
  </p>
</template>

<script>
// 默认宽图片,占满父亲宽度,传入图片名name="xx"无后缀 alt='' size="s"(s小号图片。默认撑满父亲50%)
export default {
    props: {
        name: {
            default: ''
        },
        alt: {
            default: ''
        },
        size: {
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>

</style>