<template>
  <component :is="commonComponent" id="blog" title="Blogs">
    <section id="blog-content" class="pc_padding">
      <h1 class="m-b-30 head-1">
        Stress Management Guide: 5 Scientific Methods to Help You Relieve Stress
      </h1>

      <div class="autor m-b-30">
        <div>Author: Emily </div>
        <div>Publish: 2024/9/11 </div>
      </div>
      <div class="slish m-b-20" />
      <AdComponent ref="ads-7" class="m-b-40 " :ads="adsensConfig.ad7"
			:showDebug="showDebug" />
      <p>
        Life is filled with countless questions and challenges, and sometimes the journey of finding answers is itself a source of inspiration and growth. The Answer Book is a unique self-exploration tool that can help us gain deep insights during this process. In this article, we will explore how to inspire creativity through the “Daily Challenge” and share moments of inspiration that users have experienced while using the Answer Book.
      </p>
<myimg name='i5' alt='' />
<h2>Daily Challenge: Inspiring Creativity and Self-Reflection</h2>
<AdComponent ref="ads-8" class="m-b-40 " :ads="adsensConfig.ad8"
:showDebug="showDebug" />
<h3>Setting the Challenge</h3>
<p>The “Daily Challenge” invites you to ask a question each day and record the answer you receive through the Answer Book. This challenge is designed to help users keep their thinking active while gradually delving deeper into their inner selves.</p>

<h3>Benefits of the Challenge</h3>
<ol>
    <li><strong>Develop a Habit of Reflection:</strong> Writing down a question and answering it daily cultivates a habit of reflection. Over time, users become more aware of their thoughts and emotions.</li>
    <li><strong>Enhance Self-Awareness:</strong> This challenge allows users to explore their inner worlds, helping them better understand their needs and desires.</li>
    <li><strong>Stimulate Creativity:</strong> Continuous questioning and reflection can spark creativity, enabling users to generate unique ideas when tackling problems.</li>
</ol>

<h3>How to Participate in the Daily Challenge</h3>
<ul>
    <li><strong>Choose a Question:</strong> Each day, select a meaningful question. It can pertain to any aspect of life, work, emotions, or personal growth. For instance, you might ask, “What is my goal today?” or “How can I manage stress better?”</li>
    <li><strong>Record Your Answers:</strong> Use the Answer Book to document your thoughts and responses. Aim to describe your feelings and insights in detail. This not only enhances self-understanding but also provides valuable material for future reflection.</li>
    <li><strong>Review Regularly:</strong> Set aside time each week or month to review your answers. Observe how your thoughts have evolved over time. This process allows you to clearly see your progress and achievements.</li>
</ul>

<h3>Moments of Inspiration: Sparks of Insight and Creativity</h3>
<p>Throughout their use of the Answer Book, many users have shared transformative moments of inspiration. These instances not only ignite their creative sparks but also help them discover new directions in life.</p>

<h4>Case Sharing</h4>

<h5>Case 1: Lili's Musical Inspiration</h5>
<p>Lili is a young singer-songwriter passionate about music. However, she faced confusion and frustration during a creative bottleneck. To overcome this hurdle, she decided to join the Daily Challenge.</p>
<p>One day, she posed a question in the Answer Book: “How can I find new inspiration for my creations?” This question prompted her to reflect on her past creative experiences and recall moments that once excited her. As she wrote about her childhood musical enlightenment, memories flooded back, reminding her of the songs and artists that had influenced her growth.</p>
<p>Through this process, Lili suddenly came up with a new idea: to merge her childhood experiences with her current emotions and craft a song about growth and dreams. She acted on this inspiration, ultimately producing a widely loved piece. Lili's journey illustrates that inspiration often lies in our seemingly trivial moments, and the Answer Book serves as a bridge to uncover these insights.</p>

<h5>Case 2: Xiao Ming's Career Transformation</h5>
<p>Xiao Ming is an engineer who has been striving in the workplace for years. Recently, he contemplated transitioning to the photography industry, a field he has always adored. However, doubts and anxieties surrounded this decision. During a session with the Answer Book, he opted to engage in the Daily Challenge.</p>
<p>He asked himself, “What should my first step be in transitioning to photography?” Reflecting on this question led Xiao Ming to evaluate his interests and skills. He reminisced about the stunning moments he captured during past travels and the praise he received from friends for his photography.</p>
<p>As he articulated his thoughts, he penned a series of photos he had taken along with their backstories. These reflections not only reignited his passion but also revealed that he possessed the foundational skills necessary for his transition. Eventually, Xiao Ming enrolled in a photography course, successfully turning his hobby into a career through ongoing learning and practice.</p>

<h5>Case 3: Xiaoya's Artistic Pursuit</h5>
<p>Xiaoya is a young illustrator who encountered a career development bottleneck, feeling that her work lacked recognition and was filled with frustration. By chance, she discovered the Answer Book and decided to use it to explore her artistic style and career aspirations.</p>
<p>In her Answer Book, she posed the following questions:</p>
<ul>
    <li>“How can my artwork better reflect my inner world?”</li>
    <li>“What do I hope to achieve in my future artistic career?”</li>
</ul>
<p>Reflecting on these queries, Xiaoya realized she had been solely pursuing market recognition, neglecting her true passions. She began to re-examine her work, return to her original creative intentions, and experiment with new styles. Throughout this process, she not only found her unique artistic voice but also gradually gained appreciation and recognition from her audience. This transformation reignited her creative passion and led to significant breakthroughs in her career.</p>

<h5>Case 4: Aqiang's Workplace Adversity</h5>
<p>Aqiang is an ordinary programmer who experienced deep frustration a few years ago due to challenges at work. Faced with project pressures and criticism from supervisors, he began to doubt his abilities and even considered resigning. However, instead of giving up, he turned to the Answer Book for self-reflection.</p>
<p>In his reflections, he recorded his feelings and posed some profound questions:</p>
<ul>
    <li>“What do I truly want from my work?”</li>
    <li>“What are my strengths and weaknesses?”</li>
    <li>“How can I communicate more effectively with my team?”</li>
</ul>
<p>After a period of introspection, Aqiang discovered that he needed to enhance his communication skills within the team. He started actively participating in team meetings, sharing his insights, and seeking feedback. At the same time, he recognized the need to improve his technical skills, leading him to create a learning plan and utilize his spare time for self-enrichment.</p>
<p>This process not only bolstered his professional capabilities but also strengthened his relationships with colleagues. Ultimately, Aqiang was promoted, and his self-confidence gradually returned. He credited the Answer Book for the inspiration and growth it facilitated, enabling him to navigate through adversity successfully.</p>

<h2>Conclusion</h2>
<p>The Daily Answer Challenge is more than just a simple recording exercise; it provides an opportunity for deep self-exploration and inspiration. As a tool for this challenge, the Answer Book helps users continuously uncover new possibilities in their daily lives, stimulate creativity, and promote personal growth.</p>
<p>Moments of inspiration often arise unexpectedly, and through ongoing reflection and documentation, we can capture these moments and transform them into actionable insights. Embrace the challenges and let the Answer Book be your trusted companion as you explore your inner self and create your future. Regardless of the stage of life you are in, the courage to ask questions and engage in positive reflection will lead to unexpected inspiration and rewards.</p>


    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  name: 'Blog1',
  components: {
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
	metaInfo() {
		return {
			title: require('@/blogs').default[4].title,
			meta: [
				{
					name: 'description',
					content: require('@/blogs').default[4].desc,
				},
			],
		}
	},
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",

    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end
  },
};
</script>
