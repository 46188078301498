<template>
  <section ref="homme" :class="`home detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar title="Blogs" :deviceType="deviceType" />

    <div class="time-page-card-container pading-l-r p-t-30">
      <div class="time-page-card-row">
        <div v-for="(data,i) in card" :key="i" class="time-page-card-item"
          @click="goto(data.url)">
          <div class="time-page-card-image">
            <img :src="data.img" :alt="data.title">
          </div>
          <div class="time-page-card-content">
            <div class="time-page-card-title">
              {{ data.title }}
            </div>
            <div class="time-page-card-desc">
              {{ data.desc }}
            </div>
          </div>
          <div class="time-page-card-btn">
            Read more
          </div>
        </div>
      </div>
    </div>

    <FootBar :deviceType="deviceType" />
  </section>
</template>

<script>
import common from '@/mixin/common.js';
import HeadBar from '@/pages/components/head.vue';
import FootBar from '@/pages/components/footer.vue';

import { mapGetters } from 'vuex';
import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    HeadBar,
    FootBar
  },
  mixins: [common],
  data () {
    return {
			card: [
				{
					img: require('@/assets/i1.png'),
					title: '"OneBook": Transformative Success Stories',
					desc: "In today's fast-paced, information-saturated world, where social media is omnipresent, many individuals find themselves feeling lost and anxious in their pursuit of success. However, 'OneBook' has served as a unique tool to guide countless individuals towards clarity. It is more than just a guide; it is a transformative tool for a lifestyle shift, enabling people to pursue happiness and achievement with a stronger sense of purpose. ",
					routename: 'blog1',
				},
				{
					img: require('@/assets/i2.png'),
					title: "OneBook: A Beacon of Wisdom and Guidance for Life's Crucial Decisions",
					desc: "OneBook is not just a collection of wisdom but a guiding light in the lives of many readers. Through real-life customer stories, we see how this book has helped individuals make the right decisions at pivotal moments, whether in career choices, emotional decisions, or repairing family relationships. When Li Lei faced a career dilemma, he turned to OneBooks and found a simple yet profound message that helped him choose the right path. Similarly, Ms. Zhang, through the book’s advice, repaired her strained relationship with her husband. These success stories show that OneBooks is more than just a book; its concise and deep insights have helped countless readers navigate their life's challenges.",
					routename: 'blog2',
				},
				{
					img: require('@/assets/i3.png'),
					title: 'The Accuracy of OneBook: Unveiling the Power of this Mysterious Book',
					desc: "OneBook is a fascinating and mysterious book that has captured the attention of many seeking guidance in life. From personal dilemmas and emotional uncertainties to career choices and everyday decisions, OneBook provides brief responses that help individuals navigate life’s uncertainties. But how accurate are its predictions? Why do so many claim that this book leads them in the right direction? In this article, we will explore the accuracy of OneBook and analyze its unique role in psychology, philosophy, and personal growth.",
					routename: 'blog3',
				},
				{
					img: require('@/assets/i4.png'),
					title: 'How to Interpret OneBook – Unveiling the Wisdom Behind It',
					desc: "OneBook is a unique tool with a mysterious allure, frequently used by those seeking answers to life's questions. Whether it's emotional dilemmas, career decisions, or everyday uncertainties, the book provides brief yet impactful responses that guide readers toward reflection and self-discovery. Its charm doesn't just lie in the simplicity of its answers but in how one interprets and applies these insights. This article will explore how to interpret OneBook, using SEO-optimized keywords like “OneBook meaning,” “how accurate is OneBook,” and “how to use OneBook,” while also demonstrating its role in fostering personal growth and decision-making.",
					routename: 'blog4',
				},
				{
					img: require('@/assets/i5.png'),
					title: 'Industry Insight Report: Trends and Insights of "OneBook"',
					desc: "In today's rapidly evolving knowledge economy, the ability to acquire and interpret information is becoming increasingly important. Particularly in areas such as self-improvement, mental health, and interpersonal relationships, people's craving for spiritual nourishment has made various psychology and philosophy books popular. 'OneBook' as a classic work that explores a variety of life questions, has gained widespread attention for its profound wisdom and practical guidance. This report will analyze current industry trends based on data related to 'OneBook' and showcase our company’s expertise in this field",
					routename: 'blog5',
				},
				{
					img: require('@/assets/i6.png'),
					title: 'OneBook: Common Questions and Unique Responses, and Why People Are Drawn to Divination from a Psychological Perspective',
					desc: "OneBook is a fascinating and interactive divination tool that provides brief yet insightful responses to common life questions. It not only offers philosophical guidance but also draws people seeking clarity and inner reflection. In this article, we will explore OneBook from two angles: first, by listing some common questions and showcasing the book’s unique and detailed responses; second, by analyzing from a psychological perspective why people are drawn to divination books like OneBook and how these tools help them better understand their decisions.",
					routename: 'blog6',
				},

			],
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
		const arr = require('@/blogs/index.js').default
		this.card = arr.map((e ,i) => ({
			title: e.title,
			desc: e.desc,
			routename: 'blog' + (i + 1),
			img: require(`@/assets/i${i + 1}.png`)
		}))
	},
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    goto (name) {
      this.$router.push('/blogs/'+name)
    }
  },
}
</script>
